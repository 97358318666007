<template>
  <mosaic-snackbar
    color="success"
    :message="message"
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    :contained="contained"
  />
</template>

<script setup lang="ts">
import { isString } from 'lodash';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    objectType?: string;
    action?: string;
    message?: string;
    contained?: boolean;
  }>(),
  {
    action: 'saved',
  }
);
if (!(props.action && props.objectType) && !props.message && props.message != '')
  throw 'One of action or message should be set for MosaicSuccessSnackbar';

const message = computed(() => {
  if (isString(props.modelValue)) return props.modelValue;
  return props.message || `${props.objectType} ${props.action}`;
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
</script>
