<template>
  <mosaic-dialog v-model:active="activeNdtDialog" :title="title" :error-message="errorMessage">
    <span :class="{ 'pb-2': $slots.default?.() }">Are you sure you want to delete "{{ objectName }}"?</span>
    <slot></slot>
    <div v-if="deletingStudentData" class="mt-2">
      <div class="mb-2">
        This will permanently delete {{ traineeNounCapitalised() }} data. Double check that you are happy for this data
        to be removed (and if you're not sure then contact support).
      </div>
      <div>If you're happy to proceed then type "permanently delete" below:</div>
      <mosaic-text-field
        v-model="permanentlyDelete"
        name="perm-delete"
        :placeholder="`Type &quot;permanently delete&quot; to delete ${traineeNounCapitalised()} data`"
        label=""
        no-icon
      />
    </div>
    <template #buttons>
      <v-btn
        variant="text"
        ripple
        color="error"
        :disabled="processing || !canDelete"
        :loading="processing"
        @click.prevent="submitDeleteItem()"
        >Delete</v-btn
      >
    </template>
  </mosaic-dialog>
</template>

<script>
import { capitaliseFirstLetters } from '@/utils/text';
import { handleErrorCodes } from '@/composables/processing-and-errors';
import { isString } from 'lodash';

export default {
  name: 'MosaicDeleteDialog',
  props: {
    active: { type: Boolean, required: true },
    objectType: { type: String, required: true },
    objectName: { type: String, required: true },
    url: { type: String, required: true },
    deletingStudentData: { type: Boolean, default: false },
    errorCodeMap: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:active', 'delete'],
  data: function () {
    return {
      activeNdtDialog: this.active,
      processing: false,
      errorMessage: '',
      permanentlyDelete: '',
    };
  },
  computed: {
    title() {
      return `Delete ${capitaliseFirstLetters(this.objectType)}`;
    },
    canDelete() {
      return !this.deletingStudentData || this.permanentlyDelete === 'permanently delete';
    },
  },
  watch: {
    activeNdtDialog(x) {
      this.$emit('update:active', x);
    },
    active(x) {
      this.activeNdtDialog = x;
    },
    url() {
      this.errorMessage = '';
    },
  },
  methods: {
    async submitDeleteItem() {
      this.processing = true;
      this.errorMessage = '';
      try {
        const error = await handleErrorCodes(() => this.$api.delete(this.url), this.errorCodeMap);
        if (isString(error)) {
          this.errorMessage = error;
        } else {
          this.activeNdtDialog = false;
          this.permanentlyDelete = '';
          this.$emit('delete');
        }
      } catch (e) {
        console.log(e);
        this.errorMessage = `Sorry, cannot delete your ${this.objectType} at the moment`;
      }
      this.processing = false;
    },
  },
};
</script>
