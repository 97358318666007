<template>
  <div>
    <mosaic-list :items="modelValue" :empty-text="`No ${objectType}s added yet`">
      <template #item="{ item: item, index: index }"
        ><div class="d-flex flex-wrap align-center my-2">
          <slot name="item" v-bind="{ item }"></slot>
          <mosaic-icon-btn
            icon="mdi-delete"
            :tooltip="`Delete ${objectType}`"
            @click.prevent="deleteItem(index)"
          ></mosaic-icon-btn>
        </div>
      </template>
    </mosaic-list>
    <div class="d-flex pr-2">
      <div class="flex-grow-1"></div>
      <mosaic-btn v-if="showClearAll && modelValue.length > 0" text @click.prevent="clearAll()">Clear All</mosaic-btn>
    </div>
    <mosaic-card-subheading class="pt-2">Add new {{ objectType }}</mosaic-card-subheading>
    <div class="d-flex flex-wrap align-center">
      <slot name="new-item" v-bind="{ newObject }"></slot>
      <mosaic-btn :disabled="!canAddNewObject" @click.prevent="add()">Add</mosaic-btn>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
const props = defineProps<{
  modelValue: T[];
  objectType: string;
  newObject: T;
  canAddNewObject: boolean;
  showClearAll?: boolean;
}>();
const emit = defineEmits(['update:add-object', 'update:modelValue']);
function add() {
  emit('update:add-object');
}
function deleteItem(index: number) {
  emit(
    'update:modelValue',
    props.modelValue.filter((x: T, i: number) => i !== index)
  );
}
function clearAll() {
  emit('update:modelValue', []);
}
</script>
