<template>
  <v-dialog
    :aria-labelledby="title"
    v-model="activeVDialog"
    :max-width="width"
    @click:outside="$emit('clickOutside')"
    :persistent="persistent"
  >
    <v-sheet>
      <div class="dialog-body">
        <div class="d-flex px-4 mb-4 py-3 align-center text-white bg-primary">
          <div class="text-h7 flex-grow-1">
            {{ title }}
          </div>
          <slot name="header" />
        </div>
        <div v-if="!!props.errorMessage" class="bg-white error-section px-4 mb-4">
          <mosaic-alert role="alert" aria-live="polite" class="my-0" density="compact" color="error" variant="flat">
            {{ props.errorMessage }}
          </mosaic-alert>
        </div>
        <div class="d-flex flex-column bg-white content-section px-4 mb-2">
          <slot />
        </div>
        <div class="button-section px-2 mb-2">
          <div class="d-flex justify-end ga-4">
            <mosaic-btn
              variant="text"
              v-if="!props.hideClose"
              @click.prevent="close()"
              :aria-label="closeButtonText"
              :disabled="processing"
              >{{ closeButtonText }}</mosaic-btn
            >
            <slot name="buttons" />
          </div>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    active: boolean;
    title: string;
    errorMessage?: string | null;
    width?: string | number;
    closeButtonText?: string;
    processing?: boolean;
    persistent?: boolean;
  }>(),
  {
    width: 650,
    closeButtonText: 'cancel',
    errorMessage: null,
    hideClose: false,
    processing: false,
  }
);

const emit = defineEmits<{
  (e: 'update:active', active: boolean): void;
  (e: 'close'): void;
  (e: 'clickOutside'): void;
}>();

const activeVDialog = ref(props.active);

watch(
  () => props.active,
  val => {
    activeVDialog.value = val ? true : false;
  }
);

watch(activeVDialog, val => {
  emit('update:active', val);
});

const close = () => {
  activeVDialog.value = false;
  emit('close');
};
</script>

<style lang="css" scoped>
.button-section,
.error-section {
  flex-shrink: 0;
}
.content-section {
  overflow-y: auto;
  flex-grow: 1;
  min-height: 30%;
  position: relative;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
.dialog-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 80vh;
}
.error-section {
  border-bottom: none !important;
}
</style>
