<template>
  <div class="menu-item">
    <v-list-item
      :to="
        isSideBarListParent
          ? undefined
          : {
              name: pageName,
              params: routingParams,
            }
      "
      :lines="subtitle ? 'two' : 'one'"
      :active="false"
      v-on="isSideBarListParent ? { click: toggleOpen } : {}"
    >
      <template #prepend>
        <v-avatar>
          <mosaic-icon :color="isOnPageOrChildPage ? 'accent' : 'primary'" :icon="icon" />
        </v-avatar>
      </template>

      <v-list-item-title>
        {{ title }}
        <v-badge v-if="badge && !isOnPageOrChildPage" inline class="mt-0" color="accent" :content="badge"> </v-badge>
      </v-list-item-title>

      <v-list-item-subtitle v-if="subtitle" style="word-break: break-word">
        {{ subtitle }}
      </v-list-item-subtitle>

      <template #append>
        <v-list-item-action v-if="isOnPageOrChildPage && !isSideBarListParent" style="min-width: 36px">
          <v-icon color="accent">mdi-radiobox-marked</v-icon>
        </v-list-item-action>

        <v-list-item-action v-else-if="isSideBarListParent" style="min-width: 36px">
          <v-icon :color="isOnPageOrChildPage ? 'accent' : 'primary'">{{
            open ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }}</v-icon>
        </v-list-item-action>
      </template>
    </v-list-item>
    <template v-if="open">
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, default: '' },
    pageName: { type: String, required: true },
    routingParams: { type: Object, default: undefined },
    routingParamsComparer: { type: Function, default: () => true },
    children: { type: Array, default: () => [] },
    badge: { type: [String, Number, null], default: () => null },
    isSideBarListParent: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    isOnPage: function () {
      return this.$route.name === this.pageName && this.routingParamsComparer(this.$route.params);
    },
    isOnChildPage: function () {
      // This can go back to equality (rather than includes) after all student level pages have been converted
      return this.children.some(c => this.$route.name === c);
    },
    isOnPageOrChildPage: function () {
      return this.isOnPage || this.isOnChildPage;
    },
  },
  watch: {
    isOnPageOrChildPage(x) {
      if (x) this.open = true;
    },
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>
