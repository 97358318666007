<template>
  <v-card>
    <v-card class="pt-5 px-5" elevation="0">
      <slot name="title"></slot>
    </v-card>
    <v-stepper v-model="internalStep" :items="items" editable>
      <template v-for="(_, i) in steps" :key="i" #[itemSlotName(i)]>
        <slot :name="`item.${i + 1}`"></slot>
      </template>

      <template #actions>
        <div class="px-5 pb-5">
          <slot name="error"></slot>
          <mosaic-error-alert :error="saveError" :action="`save this ${objectType}`" />
          <mosaic-success-snackbar v-if="objectType" v-model="saveSuccess" :object-type="objectType" />
          <div class="d-flex justify-end">
            <v-btn
              v-if="internalStep === 1"
              class="mr-2"
              @click="
                $router.go(-1);
                scrollToTop();
              "
              >Cancel</v-btn
            >
            <v-btn
              v-if="internalStep > 1"
              class="mr-2"
              @click="
                internalStep = internalStep - 1;
                scrollToTop();
              "
              >Back</v-btn
            >
            <v-btn
              v-if="internalStep < steps.length"
              color="primary"
              :disabled="!canContinue(internalStep)"
              @click="
                internalStep = internalStep + 1;
                scrollToTop();
              "
              >Continue</v-btn
            >
            <slot name="actions" :step="internalStep"></slot>
            <template v-if="save && internalStep == steps.length">
              <mosaic-btn
                v-if="isEditing"
                class="mr-2"
                :disabled="!internalCanSave"
                :loading="justSaving"
                @click="justSave()"
                >Save</mosaic-btn
              >
              <mosaic-btn
                color="primary"
                :disabled="!internalCanSave"
                :loading="savingAndReturning"
                @click="saveAndReturn()"
                >{{ !isEditing ? 'Create' : 'Save' }} and Return</mosaic-btn
              >
            </template>
          </div>
        </div>
      </template>
    </v-stepper>
  </v-card>
</template>

<script setup lang="ts">
import { ref, watch, watchEffect, computed, toRef } from 'vue';
import { withProcessingAndError, type AsyncOperationResult } from '@/composables/processing-and-errors';
import type { RouteLocationNamedRaw } from 'vue-router';
import { withSaveAndReturn } from '@/composables/save-and-return';

const props = withDefaults(
  defineProps<{
    step?: number;
    steps: string[];
    canContinue?: (step: number) => boolean;
    save?: () => AsyncOperationResult;
    objectType?: string;
    canSave?: boolean;
    isEditing?: boolean;
    returnTo: RouteLocationNamedRaw;
  }>(),
  {
    canContinue: () => true,
  }
);

const items = computed(() => props.steps.map(s => ({ title: s })));

const itemSlotName = (i: number) => `item.${i + 1}` as const;

const emit = defineEmits(['update:step']);

const internalStep = ref(props.step || 1);
watch(
  () => props.step,
  step => {
    if (step) {
      internalStep.value = step;
    }
  }
);
watchEffect(() => emit('update:step', internalStep));

const internalSave = props.save || (() => Promise.resolve());
if (props.save && (props.objectType === undefined || props.isEditing === undefined || props.returnTo === undefined)) {
  throw `objectType, returnTo and isEditing must be supplied as props if save is supplied`;
}

const { error: saveError, action: saveWithErrorHandling } = withProcessingAndError(internalSave);

const returnTo = toRef(props, 'returnTo');
const internaReturnTo = computed(() => {
  if (!returnTo.value) throw 'Impossible state, just need to satisfy the type checker';
  return returnTo.value;
});
const { saveSuccess, justSaving, savingAndReturning, justSave, saveAndReturn } = withSaveAndReturn(
  saveWithErrorHandling,
  internaReturnTo
);

const internalCanSave = computed(() => !(justSaving.value || savingAndReturning.value) && props.canSave);

function scrollToTop() {
  window.scrollTo(0, 0);
}
</script>
