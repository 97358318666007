<template>
  <div>
    <mosaic-loading-and-error-cards :object-type="objectType" :load="load" :object-type-is-plural="objectTypeIsPlural">
      <mosaic-save-card
        :object-type="objectType"
        :object-type-is-plural="objectTypeIsPlural"
        :is-creating="isCreating"
        :readonly="readonly"
        :save="save"
        :can-save="canSave && dirty"
        :return-to="returnTo"
        :hide-return="hideReturn"
        :return-is-primary="returnIsPrimary"
        :valid="valid"
        :error-code-map="errorCodeMap"
      >
        <mosaic-create-edit-title
          :object-type="objectType"
          :is-creating="isCreating"
          :title-chip="titleChip"
          :subtitle="subtitle"
          :readonly="readonly"
        >
          <template #title-actions v-if="$slots['title-actions']">
            <slot name="title-actions"></slot>
          </template>
        </mosaic-create-edit-title>
        <div>
          <slot></slot>
        </div>

        <template #buttons v-if="$slots.buttons">
          <slot name="buttons"></slot>
        </template>
      </mosaic-save-card>
    </mosaic-loading-and-error-cards>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      :object-type="objectType"
      :object-type-is-plural="objectTypeIsPlural"
      :save="save"
      :can-save="canSave"
      :error-code-map="errorCodeMap"
    />
  </div>
</template>

<script setup lang="ts">
import type { RouteLocationNamedRaw } from 'vue-router';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { toRef } from 'vue';

const props = withDefaults(
  defineProps<{
    objectType: string;
    isCreating: boolean;
    load: () => Promise<void>;
    dirty: boolean;
    canSave: boolean;
    save: () => Promise<unknown | string>;
    returnTo: RouteLocationNamedRaw;
    titleChip?: { text: string; color: string; tooltip: string };
    subtitle?: { action: string; name: string; actionAt: string };
    readonly?: boolean;
    returnIsPrimary?: boolean;
    objectTypeIsPlural?: boolean;
    valid?: boolean;
    hideReturn?: boolean;
    errorCodeMap?: { [errorCode: string]: string };
  }>(),
  {
    valid: true,
  }
);

const { dialog } = useUnsavedChanges(toRef(() => props.dirty));
</script>
