<template>
  <div>
    <mosaic-error-alert
      :error="saveError"
      :action="`save ${objectTypeIsPlural ? 'these' : 'this'} ${objectType}`"
      :override-error-message="customSaveError"
    />
    <mosaic-success-snackbar v-model="saveSuccess" :object-type="capitaliseFirstLetter(objectType)" location="bottom" />
    <mosaic-error-snackbar
      v-model="invalidSnackbar"
      :message="`This ${objectType} is either not complete or not valid. Please check and fix any errors.`"
    />
    <div class="d-flex flex-wrap justify-end align-center" style="row-gap: 4px">
      <div class="pr-4">
        <slot name="beside-buttons"></slot>
      </div>
      <mosaic-btn
        v-if="!hideReturn"
        :class="{ 'mr-2': !readonly || $slots.buttons }"
        :color="(readonly && !$slots.buttons) || returnIsPrimary ? 'primary' : 'default'"
        @click="cancel()"
        >{{ readonly ? 'Return' : 'Cancel' }}</mosaic-btn
      >
      <slot name="buttons" />
      <template v-if="!readonly">
        <mosaic-btn
          v-if="!isCreating"
          :class="{ 'mr-2': !hideReturn }"
          :color="hideReturn ? 'primary' : 'default'"
          :disabled="!internalCanSave"
          :loading="justSaving"
          @click="justSave()"
        >
          Save
        </mosaic-btn>
        <mosaic-btn
          v-if="!hideReturn"
          color="primary"
          :disabled="!internalCanSave"
          :loading="savingAndReturning"
          @click="saveAndReturn()"
        >
          {{ isCreating ? 'Create' : 'Save' }} and Return
        </mosaic-btn>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { withSaveAndReturn } from '@/composables/save-and-return';
import { isString } from 'lodash';
import { delay } from '@/utils/async';
import { handleErrorCodes } from '@/composables/processing-and-errors';

const props = withDefaults(
  defineProps<{
    save: () => Promise<unknown | string>;
    canSave: boolean;
    objectType: string;
    returnTo?: RouteLocationNamedRaw;
    objectTypeIsPlural?: boolean;
    valid?: boolean;
    hideReturn?: boolean;
    triggerSave?: boolean;
    isCreating?: boolean;
    readonly?: boolean;
    returnIsPrimary?: boolean;
    preview?: boolean;
    errorCodeMap?: { [errorCode: string]: string };
  }>(),
  {
    valid: true,
    errorCodeMap: () => ({}),
  }
);

const emit = defineEmits<{
  (e: 'update:triggerSave', triggerSave: boolean): void;
}>();

if (!props.hideReturn && !props.returnTo) {
  throw 'MosaicSaveButton must have a `returnTo` prop supplied if not hiding return';
}

function cancel() {
  justReturn();
}

const saveError = ref(false);
const customSaveError = ref('');
const invalidSnackbar = ref(false);
async function saveWithErrorHandling(): Promise<boolean> {
  if (props.preview) {
    await delay(500);
    return true;
  }

  // Consider integrating MosaicForm here
  if (!props.valid) {
    invalidSnackbar.value = true;
    return false;
  }
  if (!props.canSave) return false;

  saveError.value = false;
  customSaveError.value = '';
  try {
    const error = await handleErrorCodes(props.save, props.errorCodeMap);
    if (isString(error)) {
      customSaveError.value = error as string;
      saveError.value = true;
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log(e);
    saveError.value = true;
    return false;
  }
}

const { saveSuccess, justSaving, savingAndReturning, justSave, saveAndReturn, justReturn } = withSaveAndReturn(
  saveWithErrorHandling,
  toRef(props, 'returnTo')
);
const internalCanSave = computed(() => !(justSaving.value || savingAndReturning.value) && props.canSave);

watch(
  () => props.triggerSave,
  async triggerSave => {
    if (triggerSave) {
      if (!justSaving.value && !savingAndReturning.value) {
        await justSave();
      }
      emit('update:triggerSave', false);
    }
  }
);
</script>
