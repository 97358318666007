<template>
  <div>
    <v-snackbar
      :model-value="modelValue"
      :color="color"
      :variant="variant"
      :location="contained ? 'top' : 'bottom'"
      :contained="contained"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <div class="text-center">{{ message }}</div>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: boolean;
    message: string;
    color: 'error' | 'success' | 'accent' | 'warning';
    variant?: 'flat' | 'outlined' | 'elevated' | 'text' | 'tonal';
    contained?: boolean;
  }>(),
  {
    color: 'success',
    variant: 'elevated',
  }
);

export type SnackbarDetails = {
  active: boolean;
  message: string;
  color: 'error' | 'success' | 'accent' | 'warning' | undefined;
  timeout?: number;
};

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
</script>
